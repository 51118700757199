<template>
  <v-dialog v-model="dialog.show" max-width="450px">
    <v-card class="pb-3">
      <v-card-text class="text-center py-4">
        <h3 class="title my-3 background--text" :class="{'error--text': dialog.title === $ml.get('general_dialog_error')}">{{dialog.title}}</h3>
        <p class="mb-4 background--text">{{ dialog.message }}</p>

        <v-card-actions>
          <v-layout wrap justify-space-around>
            <v-btn class="lowercase px-5" color="primary" depressed @click.native="dialog.action" v-if='dialog.hasAction' >{{ $ml.get('general_accept') }}</v-btn>
            <v-btn class="lowercase px-5" color="accent" depressed @click.native="dialog.show = false" v-if='dialog.hasAction'>{{ $ml.get('general_cancel') }}</v-btn>
            <v-btn class="lowercase px-5" color="primary" depressed @click.native="dialog.show = false" v-else-if="!dialog.return">{{ $ml.get('general_accept') }}</v-btn>
            <v-btn class="lowercase px-5" color="accent" depressed @click.native="dialog.show = false" :to="{path: '/'}" v-else>{{ $ml.get('general_go_home') }}</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MessageDialog',
  /*
    dialog: {
      title: string,
      message: string,
      show: boolean,
      return: boolean,
      hasAction: boolean,
      action: function().bind(this)
    }
  */
  props: {
    dialog: Object
  }
}
</script>
